.App .app-content {
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
}

.App .ant-table-pagination.ant-pagination {
    align-items: center;
}

.nav .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item {
    border-bottom: 0;
}
.nav .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover {
    border-bottom: 0;
}

.loading.cover-content {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
.loading.cover-page {
    position: fixed;
    width: 100%;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
}
.loading .ant-spin {
    color: #3e79f7;
}

.button-as-text.ant-btn-link {
    color: inherit;
    font-weight: inherit;
}

th.column-total,
td.column-total {
    font-weight: bold;
}
th.column-total-fill,
td.column-total-fill {
    background-color: rgba(87, 140, 255, 0.11)!important;
}
th.column-total-fill-2,
td.column-total-fill-2 {
    background-color: rgba(138, 106, 37, 0.11)!important;
}
.t-vertical-align {
    display: flex;
    align-items: center;
    justify-content: center;
}
.push {
    margin-left: auto !important;
}
.ant-table-tbody > tr.ant-table-row.row-selected > td {
    background: rgb(250, 250, 250);
}

.ant-table-tbody > tr.ant-table-row.row-danger td {
    color: red !important;
}
.ant-table-tbody > tr.ant-table-row.row-danger td .ant-btn {
    color: red !important;
}
.ant-table-tbody > tr.ant-table-row.row-danger td a {
    color: red !important;
}


.shipping-table .ant-table tfoot>tr>td,
.shipping-table .ant-table tfoot>tr>th {

}
.shipping-table .ant-table-tbody>tr>td {
    padding: 5px;
}
.shipping-table .ant-table-thead>tr>th {

}
.t-center {
    text-align: center;
}
.spin-center {
    margin: 20px 0;
    padding: 30px 50px;
    text-align: center;
    background: transparent;
    border-radius: 4px;
}
.spin-center-fixed {
    position: fixed;
    top: 50%;
    left: 50%;
}

.add-pay {
    width: 100%;
}
.add-pay .autohide {
    visibility: hidden;
}
.add-pay:hover .autohide {
    visibility: visible;
}


tbody.ant-table-tbody > tr > td {
    padding-top: 3px;
    padding-bottom: 3px;
}
tfoot.ant-table-summary > tr > td {
    padding-top: 3px;
    padding-bottom: 3px;
}

.ant-table .cell-space-between {
    display: flex;
    justify-content: space-between;
}
.ant-table .cell-space-center {
    display: flex;
    justify-content: center;
}

.ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    position: absolute;
    top: 50%;
    right: 0;
    width: 1px;
    height: 1.6em;
    background-color: rgba(0,0,0,.06);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    transition: background-color .3s;
    content: '';
}

table > .ant-table-thead > tr > th {
    text-align: center;
    text-transform: uppercase;
    font-weight: 500;
}
.ant-table-thead > tr > th.table-col-selected {
    /*background: rgba(141, 156, 189, 0.1);*/
    background: #dde3f5;
    border-color: rgba(0, 0, 0, 0.03);
}
.ant-table-tbody td.table-col-selected {
    /*background: rgba(141, 156, 189, 0.1);*/
    background: #dde3f5;
    border-color: rgba(0, 0, 0, 0.03);
}

.ant-table-tbody > tr.table-row-selected > td {
    /*background: rgba(141, 156, 189, 0.1);*/
    background: #dde3f5;
    border-color: rgba(0, 0, 0, 0.03);
}




.ant-table-tbody>tr>td:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    position: absolute;
    top: 50%;
    right: 0;
    width: 1px;
    height: 1.6em;
    background-color: rgba(0,0,0,.06);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    transition: background-color .3s;
    content: '';
}

.-ant-table-tbody>tr>td {
    max-width: 240px;
    white-space: nowrap;
    /*overflow-x: clip;*/
    text-overflow: ellipsis;
}

.App .ant-table-cell-ellipsis {
    max-width: 200px;
}

.ant-table-tbody > tr > td .align-number-group {
    text-align: right;
    padding-right: 5px;
    padding-left: 5px;
    min-width: 105px;
}
.ant-table-tbody > tr > td.align-number {
    text-align: right;
    padding-right: 10px !important;
}
.p-lr-5 {
    padding-left: 5px;
    padding-right: 5px;
}
.ant-tag-mr0 .ant-tag {
    margin-right: 0;
}

.ant-form-item .ant-input-number-handler-wrap {
    display: none;
}

.checkbox-enabled .ant-checkbox-disabled .ant-checkbox-inner {
    background-color: rgb(62, 121, 247);
    border-color: rgb(62, 121, 247) !important;
}

.ant-image-preview-operations {
    color: white;
}
.ant-image-preview-switch-left, .ant-image-preview-switch-right {
    color: rgba(255, 255, 255, 0.79);
}

.no-br span br {
    display: none;
}